import { NavController } from '@ionic/angular';
import { Employee } from './../../models/employee/employee.model';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { fromUnixTime, isAfter } from 'date-fns';
import { map, tap } from 'rxjs/operators';
import { Permission } from 'src/app/core/models/permission/permission.model';

export interface LoginCredentials {
  email: string;
  password: string;
}

export interface AccessTokenResponse {
  user: any;
  accessToken: string;
  refreshToken: string;
}

export interface Payload {
  id: string;
  exp: number;
}

export interface ResetPasswordRequest {
  customerId: string;
  email: string;
  password: string;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public currentUser: BehaviorSubject<Employee> = new BehaviorSubject<Employee>(
    null
  );
  public $user: BehaviorSubject<string | null> = new BehaviorSubject<
    string | null
  >('');
  private currentUserSubject: BehaviorSubject<Employee>;

  constructor(private http: HttpClient, private navCtrl: NavController) {
    this.currentUserSubject = new BehaviorSubject<Employee>(
      JSON.parse(localStorage.getItem('currentUser'))
    );
    this.currentUser.next(this.currentUserSubject.value);
  }

  public get currentUserValue(): Employee {
    return this.currentUserSubject.value;
  }

  loginWithEmail(
    loginCredentials: LoginCredentials
  ): Observable<AccessTokenResponse> {
    return this.http
      .post<AccessTokenResponse>(
        `${environment.api}/auth/login/commerce`,
        loginCredentials
      )
      .pipe(map((res) => this.setSession(res)));
  }

  resetPassword(email: string): Observable<any> {
    return this.http.get<any>(
      `${environment.api}/mailer/recovery/employee/${email}`
    );
  }

  setSession(authResult: AccessTokenResponse) {
    const payload = this.decodeToken(authResult.accessToken);
    localStorage.setItem('accessToken', authResult.accessToken);
    localStorage.setItem('refreshToken', authResult.refreshToken);

    localStorage.setItem('expires_at', payload.expiration);
    this.currentUserSubject.next(payload.user);
    return payload;
  }

  decodeToken(token: string): any {
    const payload = JSON.parse(atob(token.split('.')[1]));
    return payload;
  }

  logout() {
    this.logOutreq().subscribe();
    localStorage.removeItem('id_token');
    localStorage.removeItem('expires_at');
    localStorage.removeItem('currentUser');
    localStorage.removeItem('filteredEmployee');
    localStorage.removeItem('tableWidth');
    localStorage.removeItem('columnWidth');
    localStorage.removeItem('currentCommerce');
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    sessionStorage.removeItem('permission');
    this.currentUserSubject.next(null);

    
    this.navCtrl.navigateRoot(['/login']);
  }

  public ifLoggedSetUser() {
    if (this.isLoggedIn()) {
      const accessToken = localStorage.getItem('id_token') || '';
      this.$user.next(this.decodeToken(accessToken).customerId);
    }
  }

  public isLoggedIn() {
    return isAfter(this.getExpiration(), new Date());
  }

  isLoggedOut() {
    return !this.isLoggedIn();
  }

  getExpiration(): Date {
    const expiration = localStorage.getItem('expires_at') || '';
    return fromUnixTime(parseInt(expiration, 10));
  }

  refreshToken(): Observable<AccessTokenResponse> {
    return this.http.get<AccessTokenResponse>(
      `${environment.api}/auth/refresh/commerce`,
      { headers: { 'Refresh-Token': 'Bearer ' + this.getToken() } }
    );
  }

  logOutreq(): Observable<string> {
    return this.http.get<string>(`${environment.api}/auth/logout/commerce`);
  }

  checkPermission(permissionName: string): Permission {
    const user: Employee = JSON.parse(localStorage.getItem('currentUser'));
    if(user.role === 'empleado'){
      const permission: Permission = new Permission('estadisticas-employee');
      user.permissions.push(permission);
    }
    return user.permissions.find(
      (permission) => permission.name === permissionName
    );
  }

  getToken(): string {
    return localStorage.getItem('refreshToken');
  }

  getTokenAccess(): string {
    return localStorage.getItem('accessToken');
  }
}
