import { ToastController } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  constructor(
    private toastController: ToastController,
    private inAppBrowser: InAppBrowser
  ) {}

  async presentToast(message: string, success: boolean, duration = 3000) {
    try {
      this.toastController
        .dismiss()
        .then(() => {})
        .catch(() => {})
        .finally(() => {});
    } catch (e) {}
    const toast = await this.toastController.create({
      message,
      duration,
      position: 'top',
      cssClass: success ? 'toast-success' : 'toast-error',
      buttons: [
        {
          side: 'start',
          icon: success ? 'checkmark-outline' : 'close-circle-outline',
        },
      ],
    });
    toast.present();
  }
  async presentToastSubscription(
    message: string,
    success: boolean,
    duration = 3000
  ) {
    try {
      this.toastController
        .dismiss()
        .then(() => {})
        .catch(() => {})
        .finally(() => {});
    } catch (e) {}
    const toast = await this.toastController.create({
      message: `
      <div class="toast-message-content">
        <ion-icon name="alert-circle-outline" style="font-size: 24px; vertical-align: middle; margin-right: 8px;"></ion-icon>
        ${message}
      </div>
    `,
      duration,
      position: 'middle',
      cssClass: 'toast-pay', // Aplica la clase personalizada
      buttons: [
        {
          text: 'Ir al panel de control',
          role: 'cancel',
          handler: () => {
            this.goToPanel();
          },
        },
      ],
    });
    toast.present();
  }

  openInAppWebsite(url: string) {
    this.inAppBrowser.create(url, '_system');
  }
  goToPanel() {
    this.openInAppWebsite(
      'https://billing.stripe.com/p/login/7sI6pW1eh8hQ7h6aEE'
    );
  }
}
