/* eslint-disable max-len */
import { Component, OnDestroy } from '@angular/core';
import { SpinnerComponent } from './shared/components/spinner/spinner.component';
import { Commerce } from './core/models/commerce/commerce.model';
import { Subscription } from 'rxjs';
import { SubscriptionService } from './core/services/subscription/subscription.service';
import { ToastService } from './core/services/toast/toast.service';
import { Router } from '@angular/router';
import { UtilsService } from './core/services/utils/utils.service';
import { LocalStorageService } from './core/services/localStorage/local-storage.service';
import { AuthService } from './core/services/auth/auth.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnDestroy {
  public spinnerComponent = SpinnerComponent;
  isMobile = false;
  subscription: Subscription = new Subscription();

  constructor(
    private subscriptionService: SubscriptionService,
    private toastService: ToastService,
    private router: Router,
    private utils: UtilsService,
    private localStorageService: LocalStorageService,
    private auth: AuthService
  ) {
    if (window.innerWidth < 768) {
      this.isMobile = true;
    }
    const currentCommerce = this.localStorageService.getCurrentCommerce();
    if (currentCommerce) {
      this.utils.getCommerceData(currentCommerce?.uuid);
      this.checkSubscriptionActive(currentCommerce);
    } else {
     // this.auth.logout();
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  private checkSubscriptionActive(currentCommerce: Commerce) {
    if (currentCommerce?.uuid) {
      this.subscription.add(
        this.subscriptionService
          .getByCommerce(currentCommerce.uuid)
          .subscribe((subscription) => {
            if (subscription) {
              const currentDate = new Date();
              const endDate = new Date(subscription.current_period_end * 1000); // Convertir la fecha Unix a milisegundos
              const diffDays = Math.ceil(
                (endDate.getTime() - currentDate.getTime()) /
                  (1000 * 60 * 60 * 24)
              );

              // Si la suscripción está en "trialing" y quedan 3 días o menos
              if (
                subscription.status === 'trialing' &&
                diffDays <= 3 &&
                diffDays >= 0
              ) {
                const trialMessage = `Su periodo de prueba termina en ${diffDays} día(s). Por favor, asegúrese de añadir el método de pago para continuar utilizando el servicio sin interrupciones o contacte con atención al cliente.`;
                this.toastService.presentToast(trialMessage, true, 15000);
              }

              // Si la suscripción está en "open" y hubo problemas con el cobro
              if (
                (subscription.status === 'open' && subscription.isActive) ||
                diffDays < 0
              ) {
                const openMessage =
                  'No se ha podido efectuar el cobro de su suscripción. Su servicio podría ser cortado pronto si no se resuelve el problema de pago. Contacte con atención al cliente.';
                this.toastService.presentToastSubscription(
                  openMessage,
                  false,
                  20000
                );
              }

              // Manejar si la suscripción está inactiva
              if (!subscription.isActive) {
                const inactiveSubMessage =
                  'Su suscripción está expirada, por favor contacte con el servicio de atención al cliente a través de info@yeasyapp.com';
                this.toastService.presentToastSubscription(
                  inactiveSubMessage,
                  false,
                  60000
                );
                this.router.navigate(['/login']);
              }
            }
          })
      );
    }
  }
}
