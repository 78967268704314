import { Component, OnInit } from '@angular/core';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';

@Component({
  selector: 'app-ad-screen',
  templateUrl: './ad-screen.component.html',
  styleUrls: ['./ad-screen.component.scss'],
  providers: [InAppBrowser],
})
export class AdScreenComponent implements OnInit {
  constructor(private inAppBrowser: InAppBrowser) {}

  ngOnInit() {}

  navigate(link: string) {
    this.inAppBrowser.create(link, '_system');
  }
}
