import { Injectable } from '@angular/core';
import { Employee } from '../../models/employee/employee.model';
import { Commerce } from '../../models/commerce/commerce.model';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  getCurrentUser(): Employee {
    return JSON.parse(localStorage.getItem('currentUser')) as Employee;
  }

  getCurrentCommerce(): Commerce {
    return JSON.parse(localStorage.getItem('currentCommerce')) as Commerce;
  }

  setCurrentCommerce(commerce: Commerce) {
    localStorage.setItem('currentCommerce', JSON.stringify(commerce));
  }

  setCurrentUser(user: Employee) {
    localStorage.setItem('currentUser', JSON.stringify(user));
  }
}
