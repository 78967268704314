import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Commerce } from '../../models/commerce/commerce.model';
import { LngLat } from '../../interfaces/long-lat.interface';
import { DaySchedule } from '../../interfaces/schedule-day.interface';
import { BannedCustomerDto } from '../../interfaces/banned-customer.dto';

@Injectable({
  providedIn: 'root',
})
export class CommerceService {
  constructor(private http: HttpClient) {}

  getCommerceInfoById(uuid: string): Observable<Commerce> {
    return this.http.get<Commerce>(`${environment.api}/commerce/${uuid}`);
  }
  getAllCommerceByEmployee(employeeId: string): Observable<Commerce[]> {
    return this.http.get<Commerce[]>(
      `${environment.api}/commerce/owner/${employeeId}`
    );
  }

  createCommerce(commerce: {
    commerceName: string;
    phone: string;
    email: string;
    password: string;
    ambassadorCode: string;
  }): Observable<Commerce> {
    return this.http.post<Commerce>(`${environment.api}/commerce`, commerce);
  }

  saveCommerce(commerce: Commerce): Observable<Commerce> {
    return this.http.put<Commerce>(`${environment.api}/commerce`, commerce);
  }
  saveCommerceBanned(commerce: BannedCustomerDto): Observable<any> {
    return this.http.post<BannedCustomerDto>(
      `${environment.api}/banned-customers`,
      commerce
    );
  }

  removeCommerceBanned(
    commerce: BannedCustomerDto
  ): Observable<BannedCustomerDto> {
    return this.http.post<BannedCustomerDto>(
      `${environment.api}/banned-customers/unblock`,
      commerce
    );
  }

  checkIfSlugExist(slug: string): Observable<Commerce[]> {
    return this.http.get<Commerce[]>(
      `${environment.api}/commerce/slug/${slug}`
    );
  }
  checkIfNameExist(name: string): Observable<Commerce[]> {
    return this.http.get<Commerce[]>(
      `${environment.api}/commerce/name/${name}`
    );
  }
  checkChangeTimetableCommerce(
    commerce: string,
    day: DaySchedule
  ): Observable<boolean> {
    return this.http.post<boolean>(
      `${environment.api}/commerce/check-booking-before-change/${commerce}`,
      day
    );
  }
  updateCommerceCoordinates(
    uuid: string,
    lngLat: LngLat
  ): Observable<Commerce> {
    return this.http.put<Commerce>(
      `${environment.api}/commerce/coordinates/${uuid}`,
      lngLat
    );
  }
  updateProtect(uuid: string, value: number): Observable<Commerce> {
    const val = {
      value,
    };
    return this.http.put<Commerce>(
      `${environment.api}/commerce/protect/${uuid}`,
      val
    );
  }
  updateInterval(uuid: string, value: number): Observable<Commerce> {
    const val = {
      value,
    };
    return this.http.put<Commerce>(
      `${environment.api}/commerce/interval/${uuid}`,
      val
    );
  }

  updateIslive(uuid: string, value: boolean): Observable<Commerce> {
    const val = {
      value,
    };
    return this.http.put<Commerce>(
      `${environment.api}/commerce/is-live/${uuid}`,
      val
    );
  }
  updateAvailability(uuid: string, value: number): Observable<Commerce> {
    const val = {
      availableWeeks: value,
    };
    return this.http.put<Commerce>(
      `${environment.api}/commerce/available-weeks/${uuid}`,
      val
    );
  }

  updateMonetization(uuid: string, value: string): Observable<Commerce> {
    const val = {
      value,
    };
    return this.http.put<Commerce>(
      `${environment.api}/commerce/monetization/${uuid}`,
      val
    );
  }
  cancelAccount(uuid: string) {}

  updateWhatsappAvailable(
    commerceId: string,
    customerEmail: string,
    priceId: string
  ): Observable<boolean> {
    return this.http.put<boolean>(
      `${environment.api}/commerce/whatsapp/${commerceId}`,
      {
        customerEmail,
        priceId,
      }
    );
  }
}
