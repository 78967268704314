import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdScreenComponent } from './ad-screen.component';
import { IonicModule } from '@ionic/angular';



@NgModule({
  declarations: [AdScreenComponent],
  exports: [AdScreenComponent],
  imports: [CommonModule, IonicModule],
})
export class AdScreenModule {}
